/* ContactSection.css */

.contact-container {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 350px;
  }
  
  .contact-icon {
    width: 40px;
    height: 40px;
    object-fit: contain;
    cursor: pointer;
  }
  
  /* Add any additional styling or animations for the contact section if desired */
  