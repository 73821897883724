/* App.css */
.app-container {
  min-height: 100vh;
  display: grid;
  grid-template-rows: repeat(4, 1fr);
  gap: 20px;
  padding: 20px;
}

.section-half-height {
  height: 55vh;
}
