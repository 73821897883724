/* ExperienceCard.css */

.experience-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 50vh;
  padding: 20px; /* Adjust as needed */
  flex-direction: column;
  margin-top: 249px;
}

.section-title {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 20px;
  font-family: 'Your Unique Font', sans-serif; /* Replace with your chosen font */
  color: #333; /* Customize the color as needed */
  text-align: center;
  width: 100%;
}

.experience-cards-row {
  display: flex;
  flex-wrap: nowrap; /* Prevent card wrapping */
  /* overflow-x: auto; Allow horizontal scrolling if needed */
justify-content: center;
    width: 100%;
}

.experience-card {
  /* flex: 0 0 calc(280px - 20px); Adjust as needed for card width and spacing */
  margin-right: 10px; /* Add spacing between the cards */
    background: linear-gradient(135deg, #f8f8f8, #ffffff);
    border: 1px solid #e1e1e1;
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        width: 300px;
    height: 374px;
    margin: 10px;
  }
  
  .company-logo {
    width: 80px;
    height: 80px;
    object-fit: contain;
    margin-bottom: 16px;
  }
  
  h2 {
    font-size: 24px;
    font-family: 'Arial', sans-serif;
    font-weight: 600;
    margin-bottom: 8px;
  }
  
  .date {
    font-size: 14px;
    color: #666666;
    margin-bottom: 8px;
  }
  
  .description {
    font-size: 16px;
    line-height: 1.6;
  }
  
  /* Media Query for Mobile Devices */
@media screen and (max-width: 768px) {
    .experience-card {
      margin: 8px 0;
      max-width: 100%;
    }
  }