.skills-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap; /* Allows the cards to wrap to the next line if needed */
    height: 50vh;
    padding: 20px; /* Adjust as needed */
  }
  
  .section-title {
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 20px;
    font-family: 'Your Unique Font', sans-serif; /* Replace with your chosen font */
    color: #333; /* Customize the color as needed */
    text-align: center;
    width: 100%;
  }
  
  .skills-card {
    flex: 0 0 calc(20% - 20px);
    margin: 10px; 
    width: 200px;
    height: 200px;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 16px;
    text-align: center;
    transition: transform 0.3s ease;
    cursor: pointer;
  }
  
  .skills-card:hover {
    transform: scale(1.1);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }
  .skill-logo {
    width: 100px;
    height: 100px;
    margin-bottom: 8px;
  }
  
  h2 {
    font-size: 18px;
  }
  
  @media screen and (max-width: 768px) {
    .skills-card {
      margin: 8px 0;
    }
  }