/* Avatar.css */

.avatar-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: linear-gradient(135deg, #ff7b7b, #a14cff);
    padding: 40px;
    border-radius: 16px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .avatar-image-container {
    width: 200px;
    height: 200px;
    border-radius: 50%;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }
  
  .avatar-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .avatar-info {
    flex: 1;
    margin-left: 40px;
    color: #fff;
  }
  
  h1 {
    font-size: 28px;
    margin-bottom: 8px;
  }
  
  p {
    font-size: 16px;
    line-height: 1.6;
  }
  
  /* Customize the colors and styles as needed */
  /* Media Query for Mobile Devices */
@media screen and (max-width: 768px) {
    .avatar-container {
      padding: 10px;
    }
  
    .avatar-image {
      width: 80px;
      height: 80px;
      margin-bottom: 10px;
    }
  }